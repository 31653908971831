import React, {useEffect, useCallback, useState} from 'react';
import './DashboardLayout.css'

import {useDispatch, useSelector} from "react-redux";
import {Link, NavLink, useHistory} from "react-router-dom";
import {client} from "../../../services/client";
import {logout, setUser} from "../../../store/actions/actions";
import {
  AppBar,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  Toolbar,
  Typography, useTheme
} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import { ExitToApp} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {isMobile} from "../../../utils/checkDevice";

import ReceiptIcon from '@material-ui/icons/Receipt';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}));

export const DashboardLayout = ({children}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const user = useSelector(store => store.base.user);
  const [drawerOpen, setDrawerOpen] = useState(false);
  let links;

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleLogout = () => {
    dispatch(logout());
  }


  const fetchCurrentUser = useCallback(async () => {
    if (user === null) {
      const user = await client.getCurrentUser();
      dispatch(setUser(user));
    }
  }, [user, history]);

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  if (user && (user.role === "ROLE_ADMIN" || user.role === "ROLE_MODERATOR")) {
    links = [
      {to: "/orders", text: "Orders", icon: <ReceiptIcon/>},
    ]
  } else {
    links = [
      {to: "/translations", text: "Translations", icon: <ReceiptIcon/>},
    ]
  }


  return (
    <>
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            {isMobile() ?
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerOpen}>
                <MenuIcon/>
              </IconButton>
              :
              <nav>
                {links.map((link, index) => (
                  <>
                    <NavLink
                      key={index}
                      className={'navLink'}
                      activeClassName={'navLinkActive'}
                      to={link.to}
                    >
                      {link.text}
                    </NavLink>
                  </>
                ))}
              </nav>
            }
            <Typography variant="h6" className={classes.title}>
            </Typography>
            <IconButton onClick={handleLogout} edge="start" color="inherit" aria-label="menu"
                        style={{marginRight: -12}}>
              <ExitToApp/>
            </IconButton>
          </Toolbar>
        </AppBar>

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
            </IconButton>
          </div>
          <Divider/>
          <List>
            {links.map((link, index) => (
              <ListItem button key={index} component={Link} to={link.to}>
                <ListItemIcon>{link.icon}</ListItemIcon>
                <ListItemText primary={link.text}/>
              </ListItem>
            ))}
          </List>
        </Drawer>
      </div>

      <div className="pageContent">{children}</div>

    </>
  )
}