import { jwt } from './jwt';
import { api } from './api';

import { API_URLS } from './apiUrls';

const BASIC_CODE = process.env.REACT_APP_BASIC_CODE;

export class Client {

  //Users

  auth2fa = ({ email, password }) =>
    api.post(
      API_URLS.OAUTH.TOKEN,
      `grant_type=password&username=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`,
      {
        headers: {
          Authorization: `Basic ${BASIC_CODE}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

  refreshToken = () =>
    api.post(
      API_URLS.OAUTH.TOKEN,
      `grant_type=refresh_token&refresh_token=${jwt.refreshToken}`,
      {
        headers: {
          Authorization: `Basic ${BASIC_CODE}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

  //Users
  getCurrentUser = () => api.get(API_URLS.USERS.CURRENT);
  getAllUsers = (params) => api.get(API_URLS.USERS.ALL, {params})
  getUserById = (id) => api.get(API_URLS.USERS.GET_BY_ID(id))
  updateProfile = (id, data) => api.put(API_URLS.USERS.UPDATE(id), data)



  //Orders
  getAllOrders = (params) => api.get(API_URLS.ORDERS.ALL, {params})
  getInvoices = (params) => api.get(API_URLS.ORDERS.INVOICES, {params})
  getOrderById = (id) => api.get(API_URLS.ORDERS.GET_BY_ID(id))
  confirmInvoice = (id) => api.put(API_URLS.ORDERS.CONFIRM_INVOICE(id))
}

export const client = new Client();
