import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {DashboardLayout} from "./components/Layout/DashboardLayout/DashboardLayout";
import {Auth} from "./pages/auth/Auth";
import {GuestLayout} from "./components/Layout/GuestLayout/GuestLayout";
import {Orders} from "./pages/orders/Orders";
import {OrdersEdit} from "./pages/orders/OrdersEdit";

export const useRoutes = (isAuth, userStatus) => {
  if (isAuth) {
    return (
      <DashboardLayout>
        <Route path="/" exact={true}>
          <Orders/>
        </Route>

        <Route path="/orders" exact={true}>
          <Orders/>
        </Route>
        <Route path="/orders/:id/edit" exact={true}>
          <OrdersEdit/>
        </Route>

      </DashboardLayout>
    )
  }
  return (
    <GuestLayout>
      <Switch>
        <Route path="/login">
          <Auth/>
        </Route>
        <Redirect to={'/login'}/>
      </Switch>
    </GuestLayout>
  )
}